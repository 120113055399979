@import "breakpoint";
@import "settings";

$light-blue: #90c8f0;
$dark-blue: #3f88c5;

@mixin text-theme($main-color, $header-color, $alt-color, $alt-color2: "") {
  p, h1, h2, h3, h4, h5, label,
  .field-name-title,
  .entity-banner-ads,
  ::placeholder {
    color: $main-color;
  }

  .ds-title h2,
  .ds-testimonial:before,
  .ds-testimonial:after,
  .field-name-title h2,
  .field-name-field-sub-text-short,
  .field-name-field-job-title,
  a.ds-gdc-number
  {
    color: $header-color;
  }

  a {
    color: $main-color;
  }
  .large-button {
    border:2px solid $header-color;
  }
  &.paragraphs-item-banner .field-name-field-link a {
    color: $main-color;
  }

  input[type='text'],
  input[type='email'],
  textarea,
  select
  {
    color: $header-color;
    border-color: $header-color;
  }

  .form-actions .webform-submit {
    @if $alt-color2 != "" {
      background: $alt-color2;
    } @else {
      background: $alt-color;
    }
    color: $alt-color;
  }

  .entity-blurbs .field-name-field-link a {
    @if $alt-color2 != "" {
      background: $alt-color2;
    } @else {
      background: $alt-color;
    }
    color: $main-color;
  }
  .map-wrapper h2,
  .map-wrapper .ds-address p:last-child {
    border-bottom-color: $main-color;
  }

  .entity-team-member .readmore {
    border-color: $alt-color;
    color: $main-color;
  }

  .map-wrapper {
    h2, h2, .ds-address p:last-child {
      border-bottom-color: $main-color;
    }
    .ds-address p:first-of-type {
      color: $alt-color2;
    }
    .ds-address h3 {
      color: $header-color;
    }
    .ds-address p {
      color: $main-color;
    }
  }

  &.selected .readmore:after {
    color: $main-color;
  }

  .paragraphs-item-team-members .team-details {
    border-top-color: $main-color;
  }

  .fixed-pricing-table .pricing-header {
    background: $main-color;
    color: invert($main-color);
  }
}


// MENU ****************************************************************************

#block-menu-block-1 > ul > li {
  > a.active-trail, > span.active-trail {
    border-bottom-color: #7ec3c3;
  }
}


// FOOTER **************************************************************************
#footer-holder h2:after {
  background: #7ec3c3;
}

.entity-blurbs .field-name-field-link a {
  padding: 10px 18px;
  background: black;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
}


// THEMES ***************************************************************************

// Default theme
.entity-paragraphs-item {
  background-color: white;
 // @extend .grey-text;
}

.fwbg .ds-text h1 {
  color: $dark-blue;
}

.white-background {
  background-color: white;
}
.light-grey-background {
  background-color: #e4e5e7;
}
.dark-grey-background {
  background-color: #909090;
}
.aqua-blue-background {
  background-color: #c5daed;
}
.dark-blue-background {
  background-color: #4087c5;
}
.pink-background {
  background-color: #e8dcec;
}


.grey-text-blue-header {
  @include text-theme(#5d5d5d, $dark-blue, #3887cc);
}

.grey-text-black-header {
  @include text-theme(#58595b, black, #7ec3c3);

  .ds-text h1 {
    color: $dark-blue;
  }

  .readmore a {
    background: #58595b;
    color: #58595b;
  }
}

.grey-text {
  @include text-theme(#58595b, $dark-blue, #58595b);
  .form-actions .webform-submit,
  .entity-blurbs .field-name-field-link a {
    background:  #58595b;
    color: white;
  }
  .readmore a {
    background: $dark-blue;
    color: white;
  }
}
.blue-text {
  color: $light-blue;
  p {
    color: white;
  }
  .ds-testimonial .field-name-field-title {
    color: $light-blue;
  }
}

.dark-blue-text {
  color: $dark-blue;
  p {
    color: $dark-blue;
  }
  #webform-client-form-4 input, #webform-client-form-4 textarea {
    color:$dark-blue;
    border-color: $dark-blue;
  }

}
.white-text-blue-header {
  @include text-theme(white, #7ec3c3, white);
  .form-actions .webform-submit,
  .entity-blurbs .field-name-field-link a {
    background:  white;
    color: #58595b;
  }
}

.white-text {
  @include text-theme(white, white, #7ec3c3, white);
  .form-actions .webform-submit {
    background:  white;
    color: #58595b;
  }

  .entity-blurbs .field-name-field-link a {
    background: $dark-blue;
    color: white;
  }
}

.pink-text {
  @include text-theme(#b28abf, #b28abf, white, #b28abf);
}

// SPECIFIC THEMING ***********************************************************************************
.carousels-carousel-page .ds-title {
  background: linear-gradient(to right, rgba(144,200,240,1) 0%,rgba(144,200,240,1) 52%,rgba(144,200,240,0) 100%);
}

.carousels-carousel-page .ds-title h2,
.carousels-carousel-page .field-name-field-call-to-action {
 color: white;
}

.entity-carousels .ds-title h2{

  @include breakpoint($desktop-only) {
    font-size:35px;
  }
}
.paragraphs-item-gallery .ds-title h2 {
  color:$dark-blue;
}

.entity-paragraphs-item .fixed-pricing-table .pricing-header {
  background: #7EC3C3 !important;
}

.paragraphs-item-team-members .row > .ds-title h2,
.paragraphs-item-list-of-other-pages .ds-title h2 {
  color: #b28abf;
}

.paragraphs-item-tiled-panels h3 {
  text-transform: none !important;
  color: #b28abf;
}

#block-menu-block-1>ul>li>ul>li>a, #block-menu-block-1>ul>li>ul>li span {
  border-bottom-color: #7ec3c3;
}

.paragraphs-item-team-members .readmore{
    border:0;
    background:$dark-blue;
    padding: 10px 18px;
    color: white !important;
    font-size:20px !important;
}
.node-news-item .readmore {
  a {
    border:0;
    background:$dark-blue;
    padding: 10px 18px;
    color: white !important;
    font-size:20px !important;
  }
}
#block-menu-block-1 ul li a {
  font-size:18px;
  @include breakpoint($desktop-only) {
    font-size:16px;
  }
}
input[type='submit']
{
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
  appearance: none;
}
#webform-client-form-4 .button-primary {
  background: $dark-blue;
  color: white !important;
  font-size:20px;
  padding: 10px 18px;
  font-weight:normal;
}

.paragraphs-item-form h2 {
  color:$dark-blue;
}

.entity-blurbs .field-name-field-link a {
  font-size:20px;
  @include breakpoint($desktop-only) {
    font-size:16px;
  }
}

.icon.email, .icon.telephone {
  &:before {
    top:0;
  }
}

.entity-team-member.selected .readmore::after {
  color: $dark-blue;
}

#topheader {
  background: $dark-blue;
}
#block-menu-block-1 > ul > li > a.active-trail, #block-menu-block-1 > ul > li > span.active-trail {
  border-bottom-color:$light-blue;
}
.owl-dot span {
  border: 2px solid $light-blue !important;
}
.owl-dot.active span {
  background: $light-blue !important;
}

table a {
  word-break: break-all;
  text-decoration: underline;
}
@media(max-width: 767px) {
  table {
    border-width: 3px;
  }
  table td,
  table th {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
  }
}

#block-block-4 a {
  text-decoration: underline;
}

#webform-client-form-4  [for=edit-submitted-i-consent-to-my-personal-data-being-collected-and-stored-as-per-the-a-href-privacy-policyprivacy-policy-a] {
  width: auto;
  margin-bottom: 5px;
}
.webform-client-form input[type=checkbox] + label {
  font-size: 20px;
}
.webform-component a {
  text-decoration: underline;
}
/* Cookie Compliance popup */
#sliding-popup .popup-content #popup-text {
  max-width: 78%;
  margin: 10px 0 8px;
}
#sliding-popup .popup-content #popup-buttons {
  max-width: 20%;
  text-align: right;
}
#sliding-popup .eu-cookie-compliance-more-button {
  font-size: 14px;
  font-weight: bold;
}
#sliding-popup .popup-content #popup-text h1, #sliding-popup .popup-content #popup-text h2, #sliding-popup .popup-content #popup-text h3, #sliding-popup .popup-content #popup-text p, #sliding-popup label, #sliding-popup div, .eu-cookie-compliance-secondary-button, .eu-cookie-withdraw-tab {
  /*font-family: 'Open Sans', sans-serif;*/
  font-weight: 600;
  line-height: 17px;
  font-size: 14px;
}
#sliding-popup .eu-cookie-compliance-secondary-button {
  cursor: pointer;
  margin-right: 5px;
  margin-top: 1em;
  vertical-align: middle;
  overflow: visible;
  width: auto;
  background-color: #6B8C55;
  color: #000;
  font-family: Arial, sans-serif;
  font-weight: bold;
  padding: 4px 8px;
  text-decoration: none;
}
#sliding-popup .eu-cookie-compliance-default-button, #sliding-popup .eu-cookie-compliance-hide-button, #sliding-popup .eu-cookie-compliance-more-button-thank-you, #sliding-popup .eu-cookie-withdraw-button, .eu-cookie-withdraw-button, .eu-cookie-compliance-save-preferences-button {
  box-shadow: none;
  border-radius: 0;
  border: none;
  text-shadow: none;
  font-size: 14px;
}
#sliding-popup .popup-content #popup-text h2 {
  font-size: 20px;
  margin-top: 5px;
}
@media screen and (max-width: 600px) {
  #sliding-popup .popup-content #popup-buttons,
  #sliding-popup .popup-content #popup-text {
    max-width: 100%;
  }
}
